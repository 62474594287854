<template>
    <!-- FOOTER -->
    <footer ref="footer_section" class="footer section-background relative">
        <div class="container-fluid">
            <div class="row row-contact">
                
                <div class="col-22 col-lg-9 offset-1">
                    
                    <h2 class="section-title">
                        Stay in Touch
                    </h2>
                    
                    <div class="body-text max-width-md-280">
                        Keep updated with our latest news. But don’t worry, we’ll never overload your inbox.
                    </div>

                    <div class="max-w-screen-sm flex lg:hidden border-bottom">
                        <!-- <input placeholder="MAIL..." class="mail-input "/>
                        <button class="mail-submit-button nav-bar-item nav-bar-item--colors">SUBMIT</button> -->
                        <div ref="onSubmitFormContainerM" class="contact-onsubmit-container-m">
                            <div>
                                <h4 class="section-title">
                                THANK YOU!
                                </h4>
                                    We have sent confirmation email to the address you provided.
                            </div>
                            <!-- <button id="send-form" type="submit" class="submit-form" @click="showForm">
                                SEND ANOTHER MESSAGE
                            </button> -->
                        </div>

                        <div ref="onErrorFormContainerM" class="contact-onsubmit-container-m">
                            <div class="subscribe-form-text-error">
                                There was a problem with subscribing you.<br>Please try again or send an email directly to <a href="mailto:contact@soir.events" _target="_blank">contact@soir.events</a>.
                            </div>
                            <div class="pull-left">
                                <button class="mail-submit-button nav-bar-item nav-bar-item--colors" @click="showForm">
                                    TRY AGAIN
                                </button>
                            </div>
                        </div>
                        <form ref="formContainerM" id="subscribe-form-m" action="">
                            <div class="mail-input-wrapper flex border-bottom">
                                    <input type="text" name="email" placeholder="MAIL..." class="mail-input "/>
                                    <button class="mail-submit-button nav-bar-item nav-bar-item--colors" type="submit">SUBMIT</button>
                            </div>
                        </form>
                    </div>

                </div>
                <div class="lg:hidden col-24">
                    <div class="row">
                        <div class="col-24">
                            <div class="section-horizontal-line"></div>
                        </div>
                    </div>
                </div>
                <div class="col-22 col-lg-11 offset-1 offset-lg-2">
                    <div class="row">
                        <div class="flex max-w-screen-sm footer-links">
                            <div class="col ">
                                <div class="list-title">DISCOVER</div>
                                <ul class="list">
                                    <li><router-link to="/features">Features</router-link></li>
                                    <!-- <li><router-link to="/pricing">Pricing</router-link></li> -->
                                    <li><router-link to="/faq">FAQ</router-link></li>
                                </ul>
                            </div>
                            <div class="col">
                                <div class="list-title">LEGAL</div>
                                <ul class="list">
                                    <li><router-link to="/cookies">Cookie policy</router-link></li>
                                </ul>
                            </div>
                            <div class="col">
                                <div class="list-title">CONTACT</div>
                                <ul class="list">
                                    <li><router-link to="/contact">Contact us</router-link></li>
                                    <!-- <li><a href="#">Press kit</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="">
                    <div class="row row-mail">
                        <div class="col-8 offset-1 hidden lg:block">
                            <div ref="onSubmitFormContainer" class="contact-onsubmit-container">
                                <div>
                                    <h4 class="section-title">
                                    THANK YOU!
                                    </h4>
                                     We have sent confirmation email to the address you provided.
                                </div>
                                <!-- <button id="send-form" type="submit" class="submit-form" @click="showForm">
                                    SEND ANOTHER MESSAGE
                                </button> -->
                            </div>

                            <div ref="onErrorFormContainer" class="contact-onsubmit-container">
                                <div class="subscribe-form-text-error">
                                    There was a problem with subscribing you. Please try again or send an email directly to <a href="mailto:contact@soir.events" _target="_blank">contact@soir.events</a>.
                                </div>
                                <div class="pull-left">
                                    <button class="mail-submit-button nav-bar-item nav-bar-item--colors" @click="showForm">
                                        TRY AGAIN
                                    </button>
                                </div>
                            </div>
                            <form ref="formContainer" id="subscribe-form" action="">
                                <div class="mail-input-wrapper flex border-bottom">
                                        <input type="text" name="email" placeholder="MAIL..." class="mail-input " id="newsletter-email"/>
                                        <button class="mail-submit-button nav-bar-item nav-bar-item--colors" type="submit">SUBMIT</button>
                                </div>
                                <div class="error-label"></div>
                            </form>
                        </div>
                        <div class="col-22 col-lg-14 offset-1 offset-lg-0">
                            <div class="copywrite lg:text-right">
                                Copyright by SOIR, 2021 Website by <a href="https://brightmedia.pl/">Bright</a>
                            </div>
                        </div>
                    </div>    
            </div>
        </div>
    </footer>
</template>

<script>
import gsap from 'gsap';
import $ from 'jquery';
import axios from 'axios';
require('jquery-validation');

export default {
    mounted(){
        gsap.to(this.$refs.footer_section, {duration:0.2, autoAlpha:1, delay:1, ease: "power4.out"});
        this.initForm();
    },
    beforeDestroy(){
        this.validator && this.validator.destroy()
        this.validator2 && this.validator2.destroy()
    },
    methods: {
        hideForm () {
            gsap.timeline()
                .to([this.$refs.formContainer, this.$refs.formContainerM], {autoAlpha: 0, duration: 0.37, ease: "power3.inOut"});
        },
        showForm(){
            gsap.timeline()
                .to([this.$refs.onSubmitFormContainer, this.$refs.onErrorFormContainer, this.$refs.onSubmitFormContainerM, this.$refs.onErrorFormContainerM], {autoAlpha: 0, duration: 0.37, ease: "power3.inOut"})
                .to([this.$refs.formContainer, this.$refs.formContainerM], {autoAlpha: 1, duration: 0.37, ease: "power3.inOut"})
        },
        showOnSubmitMessage(form){
            console.log('ON SUBMIT SUCCESS', this.$refs.onSubmitFormContainer );
            
            gsap.timeline({onComplete: () => form.reset()})
                .to([this.$refs.formContainer, this.$refs.formContainerM], {autoAlpha: 0, duration: 0.37, ease: "power3.inOut"})
                .to([this.$refs.onSubmitFormContainer, this.$refs.onSubmitFormContainerM], {autoAlpha: 1, duration: 0.37, ease: "power3.inOut"})
        },
        showErrorMessage () {
            console.log('ON SUBMIT ERROR');
            gsap.timeline()
                .to([this.$refs.formContainer, this.$refs.formContainerM], {autoAlpha: 0, duration: 0.37, ease: "power3.inOut"})
                .to([this.$refs.onErrorFormContainer, this.$refs.onErrorFormContainerM], {autoAlpha: 1, duration: 0.37, ease: "power3.inOut"});
        },
        getValidatorConfig () {
            return {
                errorPlacement: function(error, element) {
                    console.log('ERROR', error, element);
                    
                    var label = $('.error-label');
                    // error.appendTo(label);
                    label.html(error);
                },
                rules: {
                    // message: "required",
                    email: {
                        required: true,
                        email: true
                }
                },
                messages: {
                    // message: "Please enter your message",
                    email: "Please enter a valid email address"
                },
                highlight: function(element) {
                    $(element).parent().addClass('error');
                },
                unhighlight: function(element) {
                    $(element).parent().removeClass('error')
                },
                submitHandler: (form) => {
                    // alert('Submitted');
                    console.log("FORM", form);
                    this.hideForm();
                    const formData = new FormData(form);
                    // const data = formData.entries().reduce((agg, val) => {
                    //     agg[val[0]] = val[1];
                    //     return agg;
                    // }, {});
                    let data = {};
                    for (let pair of formData.entries()) {
                        data[pair[0]] = pair[1];
                        console.log("VALUE", pair[0]+ ', ' + pair[1]); 
                    }

                    console.log('DATA', data);
                    
                    axios.post('https://live.soir.events/api/newsletter/subscribe', data)
                    // axios.post('http://127.0.0.1:8000/api/ping', data)
                    .then((result) => {
                        console.log("AXIOS RESULT", result);
                        this.showOnSubmitMessage(form);
                        // this.showErrorMessage();
                    })
                    .catch((err) => {
                        console.log("AXIOS ERROR", err);
                        this.showErrorMessage();
                    })

                    // for (let pair of formData.entries()) {
                    //     console.log("VALUE", pair[0]+ ', ' + pair[1]); 
                    // }
                    //  console.log("FORM DATA", formData.entr);
                    
                    
                    //   form.submit();
                }
            }
        },
        initForm () {
            console.log('INIT FORM');
            this.validator = $("#subscribe-form").validate(this.getValidatorConfig());    
            this.validator2 = $("#subscribe-form-m").validate(this.getValidatorConfig());    
        }
    }
}
</script>