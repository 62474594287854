import * as PIXI from 'pixi.js'
import $ from 'jquery';
import gsap from 'gsap';
import SimplexNoise from 'simplex-noise'

var isMobile = require('is-mobile');

export default class {

  constructor(containerEl, scrollReactive, refresh) {
    
    this.scale = 1;

    this.scrollReactive = scrollReactive;
    this.containerEl = containerEl;
    this.simplex = new SimplexNoise();
    this.isActive = refresh;

    this.img1 = require("../assets/img/cover/01.png");
    this.img2 = require("../assets/img/cover/02.png");
    this.img3 = require("../assets/img/cover/03.png");
    this.img4 = require("../assets/img/cover/04.png");
    this.img5 = require("../assets/img/cover/05.png");
    this.img6 = require("../assets/img/cover/06.png");
    this.img7 = require("../assets/img/cover/07.png");
    this.img8 = require("../assets/img/cover/08.png");
    this.img9 = require("../assets/img/cover/09.png");
    this.img10 = require("../assets/img/cover/10.png");
    this.img11 = require("../assets/img/cover/11.png");
    this.img12 = require("../assets/img/cover/12.png");
    this.img13 = require("../assets/img/cover/13.png");
    this.img14 = require("../assets/img/cover/14.png");
    this.img15 = require("../assets/img/cover/15.png");
    this.img16 = require("../assets/img/cover/16.png");
    
    this.siluets = [this.img1, this.img2, this.img3, this.img4, this.img5, this.img6, this.img7, this.img8, this.img9, this.img10, this.img11, this.img12, this.img13, this.img14, this.img15, this.img16];
    this.peopleTextures = [];

    for (var i=0; i < this.siluets.length; i++) {
      this.peopleTextures.push (PIXI.Texture.from(this.siluets[i]));
    }

    this.isElementVisible = true;
    this.onResizeTimeout = null;
    this.scrollProgress = 0;
    this.windowWidth = 0;
    this.mouseX = 0;
    this.mouseY = 0;

    this.initPixi();

    this.onResize();
    this.onScroll();

    this.createWallpaper();
    this.onUpdate();

    gsap.timeline({
      scrollTrigger: {
          trigger: this.containerEl,
          start: "top bottom",
          end: "bottom top",
          // scrub: 0.5,
          onUpdate: self => {
            this.scrollProgress = 1 - self.progress;
            // this.app.stage.y = - $(this.containerEl).height() * this.scrollProgress
          }
      }
    })
    // .from(this.wallpaperContainer, {
    //     y: -$(this.containerEl).height(),
    //     ease: "power1.out",
    //     duration: 0.5,
    //     stagger: 0.1,
    // });

    this.onResizeBinded = () => {

      if (isMobile() == true) {
        if (this.windowWidth != window.innerWidth) {
          
            this.clearWallpaper()

            this.onResizeTimeout && clearTimeout(this.onResizeTimeout)
            this.onResizeTimeout = setTimeout( () => this.createWallpaper(), 200)
        }
      }else{
        this.clearWallpaper()

        this.onResizeTimeout && clearTimeout(this.onResizeTimeout)
        this.onResizeTimeout = setTimeout( () => this.createWallpaper(), 200)
      }

      
      this.onResize();
    };
    this.onScrollBinded = this.onScroll.bind(this);

    this.mouseMovedListener = (e) => this.mouseMoved(e)

    window.addEventListener ('resize', this.onResizeBinded);
    window.addEventListener ('scroll', this.onScrollBinded);
    window.addEventListener('pointermove', this.mouseMovedListener);
  }
  
  destroy(){
    window.removeEventListener ('resize', this.onResizeBinded);
    window.removeEventListener ('scroll', this.onScrollBinded);
    window.removeEventListener('pointermove', this.mouseMovedListener);

    this.isActive = false
    this.isElementVisible = false
  }

  initPixi(){

    this.app = new PIXI.Application({
      resolution: window.devicePixelRatio,
      autoStart: false,
      autoDensity: true,
      backgroundColor: 0x21003E,
      view: this.containerEl
    });

  }

  clearWallpaper(){
    if (this.wallpaperContainer){
      this.wallpaperContainer.parent.removeChild(this.wallpaperContainer)
      this.wallpaperContainer = null
    }
  }

  createWallpaper(){

    var xGap = 75 * 2;
    var yGap = 50;

    this.scale = window.innerWidth/1680;

    var width = (this.app.view.parentNode.clientWidth + xGap) / this.scale;
    var height = (this.app.view.parentNode.clientHeight * 2 / this.scale);
    
    var counter = 0;
    var texturesLength = this.peopleTextures.length

    this.wallpaperContainer = new PIXI.Container();    
    this.app.stage.addChild(this.wallpaperContainer);
    
    for (let i = 0; i < width / xGap; i++) {
      for (let j = 0; j < height / yGap; j++) {
        
        const sprite = new PIXI.Sprite(this.peopleTextures[counter % texturesLength]);

        var pplCont = new PIXI.Container();

              sprite.scale.x = sprite.scale.y = 0.3;

              pplCont.x = xGap * i + (j % 2 * xGap / 2) - xGap / 2;
              pplCont.y = yGap * j;
              pplCont.alpha = 0.1;
              pplCont.scale.x = pplCont.scale.y = 0.6;


              pplCont.addChild(sprite);

              sprite.x = -sprite.width/2;
              sprite.y = -sprite.height;

              this.wallpaperContainer.addChild(pplCont);

        if (counter % texturesLength == texturesLength - 1){
          this.peopleTextures = this.shuffle(this.peopleTextures);
        }

        counter ++;
      }
    }

    if (this.wallpaperContainer) this.wallpaperContainer.scale.x = this.wallpaperContainer.scale.y = this.scale;

    // this.app.render();

    setTimeout(() => {this.app.render();}, 3000)
    
  }

  mouseMoved(event){
  
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;
    
  }

  setActive(isActive){
    this.isActive = isActive;
  }

  onUpdate(){

    // if (this.isElementVisible)
    //   this.app.render();

    // console.log("this.isActive instagram", this.wallpaperContainer, " ", this.isActive);

    requestAnimationFrame(() => this.onUpdate());

    if (this.isActive){
      // requestAnimationFrame(() => this.onUpdate());

      // console.log("this.isActive instagram", this.isActive);

      // console.log("$(window).scrollTop()", $(window).scrollTop());

      var whereY = this.scrollReactive ? - $(window).scrollTop() / 5 : 0;

      this.app.stage.y += (whereY - this.app.stage.y)/2;

      this.app.render();

      var h = 0;
      var ile = this.wallpaperContainer ? this.wallpaperContainer.children.length : 0;

      for (h; h<ile; h++) {
        var pplCont = this.wallpaperContainer.getChildAt(h);
        // pplCont.scale.x = pplCont.scale.y = 0.6;

        pplCont.getChildAt(0).x = -pplCont.getChildAt(0).width/2;
        pplCont.getChildAt(0).y = -pplCont.getChildAt(0).height;

        var distance = Math.sqrt((Math.pow((pplCont.x * this.scale)- this.mouseX,2))+(Math.pow(( (pplCont.y * this.scale)-this.mouseY+this.app.stage.y),2)));
        
        // console.log(distance);

        if (Math.abs(distance) < 30 && pplCont.scale.x <= 0.6){

          gsap.to(pplCont.scale, {duration:0.2, x:0.75, y:0.75, overwrite:"all", ease: "elastic.out(1, 1.6)"});
          gsap.to(pplCont, {duration:0.2, alpha:1, overwrite:"all", ease: "power4.out"});

          gsap.to(pplCont.scale, {delay:1, duration:0.1, x:0.5, y:0.5, ease: "back.in"});
          gsap.to(pplCont, {delay:1, alpha:0.1, duration:0.1, ease: "power4.in"});
        }
      }
    }
  }

  onResize() {

      const parent = this.app.view.parentNode;

      this.scale = window.innerWidth/1680;
      if (this.wallpaperContainer) this.wallpaperContainer.scale.x = this.wallpaperContainer.scale.y = this.scale;

      this.windowWidth = window.innerWidth;
      this.app.renderer.resize(parent.clientWidth, parent.clientHeight);

      this.onScroll()
      this.app.render();
  }

  onScroll (){

    var el = $(this.containerEl);
    var top = el.offset().top - $(window).scrollTop();
    var bottom = el.offset().top + el.height() - $(window).scrollTop();

    if (top <= window.innerHeight && bottom >= 0)
      this.isElementVisible = true;
    else
      this.isElementVisible = false;
    
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }
}