<template>
    <div class="">
        <div class="nav-bar-wrapper ">
            <div class="container-fluid d-none d-md-block nav-bar-border">
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col">
                        <nav class="nav-bar nav-bar-text relative">
                            <span>
                                <router-link to="/features"  class="nav-bar-item nav-bar-dot" :class="{'active': $route.name == 'Features'}">Features</router-link>
                                <!-- <router-link to="/pricing"  class="nav-bar-item nav-bar-dot" :class="{'active': $route.name == 'Pricing'}">Pricing</router-link> -->
                                <router-link to="/faq"   class="nav-bar-item nav-bar-dot" :class="{'active': $route.name == 'FAQ'}">FAQ</router-link>
                            </span>

                            <router-link to="/"  class="nav-bar-logo-font">SOIR</router-link>
                                
                            <span class="h-full flex items-center">
                                <router-link to="/contact"  class="nav-bar-item nav-bar-dot" :class="{'active': $route.name == 'Contact'}">Contact</router-link>
                                <router-link to="/book-a-trial" class="nav-bar-item nav-bar-item--colors nav-bar-dot book-a-trial nav-bar-dot" :class="{'active': $route.name == 'BookATrial'}">Book a trial</router-link>
                            </span>
                        </nav>
                    </div>
                    <div class="col-1"></div>
                </div>
            </div>


            <div class="container-fluid d-block d-md-none">
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col nav-bar-border">
                        <nav class="nav-bar nav-bar-text relative">
                            
                            <div id="menu-mobile-button" class="nav-bar-item">MENU</div>

                            <router-link to="/" class="nav-bar-logo-font">SOiR</router-link>

                            <router-link to="/book-a-trial" class="nav-bar-item nav-bar-item--colors">
                                
                                trial
                                
                                <!-- Book a trial -->
                            </router-link>
                        </nav>
                    </div>
                    <div class="col-1"></div>
                </div>
            </div>


        </div>

        <div class="mobile-navigation d-block d-md-none">
            <div class="mobile-navigation-wrapper">
                <div class="mobile-navigation-content">
                    <div class="container-fluid height-100p">
                        <div class="row height-100p">
                            <div class="col-1"></div>
                            <div class="col-22">
                                <div class="menu-grid">
                                    <div class="menu-grid-row">
                                        <div class="menu-grid-item">
                                            <div class="menu-grid-item-inner">
                                                <router-link to="/features">
                                                    FEATURES
                                                </router-link>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="section-vertical-line"></div>
                                        </div>
                                        <div class="menu-grid-item">
                                            <div class="menu-grid-item-inner">
                                                <router-link to="/faq">
                                                    FAQ
                                                </router-link>
                                            </div>
                                        </div>
                                        <!-- <div class="menu-grid-item">
                                            <div class="menu-grid-item-inner">
                                                <router-link to="/pricing">
                                                    PRICING
                                                </router-link>
                                            </div>
                                        </div> -->
                                    </div>


                                    <div class="d-flex">
                                        <div class="flex-1">
                                            <div class="section-horizontal-line"></div>
                                        </div>
                                        <div class="flex-1">
                                            <div class="section-horizontal-line"></div>
                                        </div>
                                    </div>


                                    <div class="menu-grid-row">
                                        <!-- <div class="menu-grid-item">
                                            <div class="menu-grid-item-inner">
                                                <router-link to="/faq">
                                                    FAQ
                                                </router-link>
                                            </div>
                                        </div> -->
                                        <div class="menu-grid-item">
                                            <div class="menu-grid-item-inner">
                                                <router-link to="/contact">
                                                    CONTACT
                                                </router-link>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="section-vertical-line"></div>
                                        </div>
                                        <!-- <div class="menu-grid-item">
                                            <div class="menu-grid-item-inner">
                                                <router-link to="/contact">
                                                    CONTACT
                                                </router-link>
                                            </div>
                                        </div> -->
                                        <div class="menu-grid-item">
                                            <div class="menu-grid-item-inner">
                                                <router-link to="/book-a-trial">
                                                    BOOK A TRIAL
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- <div class="d-flex">
                                        <div class="flex-1">
                                            <div class="section-horizontal-line"></div>
                                        </div>
                                        <div class="flex-1">
                                            <div class="section-horizontal-line"></div>
                                        </div>
                                    </div>


                                    <div class="menu-grid-row">
                                        <div class="menu-grid-item">
                                            <div class="menu-grid-item-inner menu-grid-item-inner--50">
                                                <router-link to="/book-a-trial">
                                                    BOOK A TRIAL
                                                </router-link>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="d-flex">
                                        <div class="flex-1">
                                            <div class="section-horizontal-line"></div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                            <div class="col-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import gsap from 'gsap';
import $ from 'jquery';
import SplitText from '@/libs/gsap/SplitText';

var colorsSplit = [
    "#E7D2FF",
    "#FFF9CA",
    "#FCCC9B",
    "#EFFFDC",
    "#E7D2FF",
    "#C2DDED",
    "#E7D2FF",
    "#EFFFDC",
    "#FFF9CA",
    "#FCCC9B"
];

export default {

    data(){
        return {
            lastScrollY: $(window).scrollTop(),
            navBarVisibility: true,
            isNavigationActive: false,
            isNavbarTweenActive: false,
        }
    },
    mounted(){

        this.$eventHub.$on('on-before-route-change', this.onRouteWillChange);
        
        document.getElementById('menu-mobile-button').addEventListener('click', this.onMenuClick);
        
        window.isPageTransitionRunning = false;

        window.addEventListener('scroll', this.onscroll);
        window.addEventListener('resize', this.onscroll);
        window.addEventListener('mousemove', this.onMouseMove);

        $('.menu-grid-item a').click(() => {
            this.isNavigationActive = false
            this.toggleMobileMenu()
        })

        this.onscroll();
        this.activateColorfullLinks();

        gsap.to('.nav-bar-wrapper', {
                y: -62, 
                duration : 0,
                ease: 'power3.out', 
                overwrite: true});

        gsap.delayedCall(0.5, this.toggleNavbar, [true]);
    },
    beforeDestroy() {
        this.$eventHub.$off('on-before-route-change', this.onRouteWillChange);
    },
    methods: {
        gotoHome (){
            this.isNavigationActive = false;
            this.toggleMobileMenu();
            this.gotoRoute('/');
        },
        onMouseMove (e){
            if (e.clientY < 60 && this.navBarVisibility == false)
                this.onMouseOver ()
        },
        onMouseOver (){
            if (this.$route.name !== "Features")
                this.toggleNavbar (true);
        },
        onRouteWillChange(){
            this.toggleNavbar (true);
        },
        onMenuClick (){
            this.isNavigationActive = !this.isNavigationActive;
            this.toggleMobileMenu();
        },
        toggleMobileMenu(){
            
            if(this.isNavigationActive)
                document.getElementById('menu-mobile-button').innerHTML = "CLOSE";
            else
                document.getElementById('menu-mobile-button').innerHTML = "MENU";
            
            var duration = this.isNavigationActive ? 0.75 : 0.25;
            var ease = this.isNavigationActive ? 'power3.out' : 'power3.in';
            
            gsap.timeline()
                .to('.mobile-navigation', { y: this.isNavigationActive ? '0%': '-100%', duration, ease}, 'sync')
                .to('.mobile-navigation-content', { y: this.isNavigationActive ? '0%': '50%', duration, ease}, 'sync')
        },

        onscroll (){
            var newNavBarVisibility = null
            var scrollTop = $(window).scrollTop();
            
            if (!this.isNavbarTweenActive){
                if (scrollTop > 120 ) {
                    if (this.lastScrollY > scrollTop && this.navBarVisibility == false){
                        newNavBarVisibility = true
                    } else if (this.lastScrollY < scrollTop && this.navBarVisibility == true){
                        newNavBarVisibility = false
                    }
                }else if(scrollTop <= 120 && this.navBarVisibility == false){
                    newNavBarVisibility = true
                }
                
                if (newNavBarVisibility != null && newNavBarVisibility != this.navBarVisibility) {
                    this.toggleNavbar(newNavBarVisibility);
                }
            }else{
                if (scrollTop <= 120 && newNavBarVisibility == false) {
                    newNavBarVisibility = true
                }

                if (newNavBarVisibility != null && newNavBarVisibility != this.navBarVisibility) {
                    this.toggleNavbar(newNavBarVisibility);
                }
            }

            this.lastScrollY = scrollTop

        },
        
        toggleNavbar (newNavBarVisibility) {

            this.isNavbarTweenActive = true;

            gsap.to('.nav-bar-wrapper', {
                y: newNavBarVisibility ? 0 : -62, 
                duration : 0.45,
                ease: 'power3.out', 
                overwrite: true,
                onComplete: () => this.isNavbarTweenActive = false });
            
            this.navBarVisibility = newNavBarVisibility

        },

        activateColorfullLinks(){

            var naviColors = document.getElementsByClassName("nav-bar-item--colors");

            var logo = document.getElementsByClassName("nav-bar-logo-font");

            Array.prototype.forEach.call(logo, function(el) {
                var split = new SplitText( el, {type:"chars"});

                //var c = 0;

                el.dataset.c = parseInt(colorsSplit.length - 1);
                el.dataset.posStart = parseInt(colorsSplit.length - 1);

                var animation = null;

                el.addEventListener("mouseover", () => {

                    animation = gsap.to(el, {
                        duration: 0.1,
                        repeat: -1,
                        onRepeat() {
                        
                            Array.prototype.forEach.call(split.chars, function(sp) {
                                sp.style.color = colorsSplit[parseInt(el.dataset.c)];
                    
                                el.dataset.c = parseInt(el.dataset.c) + 1;
                    
                                if (parseInt(el.dataset.c) >= colorsSplit.length){
                                    el.dataset.c = 0;
                                }
                            });

                            el.dataset.posStart = parseInt(el.dataset.posStart) - 1;

                            if (parseInt(el.dataset.posStart) < 0){
                                el.dataset.posStart =  colorsSplit.length - 1;
                            }

                            el.dataset.c = parseInt(el.dataset.posStart);
                        }
                    });
                });

                el.addEventListener("mouseout", () => {
                    animation.kill();

                    var splitShuffled = split.chars;

                    var c1 = 0;

                    animation = gsap.to(el, {
                        duration: 0.1,
                        repeat: splitShuffled.length,
                        onRepeat() {
                        
                            splitShuffled[c1].style.color = "#FFFFFF";

                            c1 += 1;
                        }
                    });
                });

                // var col = parseInt(el.dataset.c);

            });

            Array.prototype.forEach.call(naviColors, function(el) {
                var split = new SplitText( el, {type:"chars"});

                //var c = 0;

                el.dataset.c = parseInt(colorsSplit.length - 1);
                el.dataset.posStart = parseInt(colorsSplit.length - 1);

                var animation = null;

                el.addEventListener("mouseover", () => {

                    animation = gsap.to(el, {
                        duration: 0.1,
                        repeat: -1,
                        onRepeat() {
                        
                            Array.prototype.forEach.call(split.chars, function(sp) {
                                sp.style.color = colorsSplit[parseInt(el.dataset.c)];
                    
                                el.dataset.c = parseInt(el.dataset.c) + 1;
                    
                                if (parseInt(el.dataset.c) >= colorsSplit.length){
                                    el.dataset.c = 0;
                                }
                            });

                            el.dataset.posStart = parseInt(el.dataset.posStart) - 1;

                            if (parseInt(el.dataset.posStart) < 0){
                                el.dataset.posStart =  colorsSplit.length - 1;
                            }

                            el.dataset.c = parseInt(el.dataset.posStart);
                        }
                    });
                });

                el.addEventListener("mouseout", () => {
                    animation.kill();
                });

                var col = parseInt(el.dataset.c);

                Array.prototype.forEach.call(split.chars, function(sp) {
                    sp.style.color = colorsSplit[col];

                    col += 1;

                    if (col >= colorsSplit.length){
                        col = 0;
                    }
                });

            });
        }
    },
    watch:{
        $route (){
            if(this.isNavigationActive){
                this.isNavigationActive = false;
                this.toggleMobileMenu ();
            }
        }
    }
}
</script>