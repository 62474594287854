<template>
    <div ref="instagram_section" class="instagram">
        <!-- INSTAGRAM -->
        <div id="section-8-top" class="section-top-border relative"></div>
        <div id="section-8" class="container-fluid section-border-bottom section-background relative bg-transparent">
            <!-- <canvas ref="canvas" class="wallpaper-canvas"></canvas> -->
            <div class="row pointer-events-none">
                <div class="col-1"></div>
                <div class="col-22 text-center">
                    <div class="section-header section-header--height240">
                        <span>FOLLOW US ON <a href="https://www.instagram.com/soir_events/" target="_blank" class="underline inline-block pointer-events-auto" rel="noreferrer"> INSTAGRAM</a></span>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>
        </div>
    </div>
</template>
<script>
import gsap from 'gsap';

export default {
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted(){
        gsap.to(this.$refs.instagram_section, {duration:0.2, autoAlpha:1, delay:1, ease: "power4.out"});
  },
  refresh: {
      type: Boolean,
      default: true
  },
  methods: {
    handleScroll () {
        //   console.log("ON SCROLL !!: ", event);

      this.refresh = this.isInViewport(document.getElementById("section-8"));

      this.$emit('componentVisibility', this.refresh)

    },

    isInViewport(element) {
        const rect = element.getBoundingClientRect();

        // console.log("TOP: ", rect.top >= -rect.height);
        // console.log("LEFT: ", rect.left >= 0);
        // console.log("BOTTOM: ", rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + rect.height);
        // console.log("RIGHT: ", rect.right <= (window.innerWidth || document.documentElement.clientWidth));

        return (
            rect.top >= -rect.height &&
            rect.left >= 0 &&
            rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + rect.height) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
  }
}

</script>