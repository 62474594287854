import Vue from 'vue'
import VueRouter from 'vue-router'
import gsap from 'gsap';
import $ from 'jquery';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomePage.vue')
  },

  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactPage.vue')
  },

  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FaqPage.vue')
  },

  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import(/* webpackChunkName: "pricing" */ '../views/PricingPage.vue')
  },

  {
    path: '/features',
    name: 'Features',
    component: () => import(/* webpackChunkName: "features" */ '../views/FeaturesPage.vue')
  },

  {
    path: '/regulations',
    name: 'Regulations',
    component: () => import(/* webpackChunkName: "regulations" */ '../views/RegulationsPage.vue')
  },

  {
    path: '/cookies',
    name: 'CookiePage',
    component: () => import(/* webpackChunkName: "cookies" */ '../views/CookiePage.vue')
  },

  {
    path: '/jobs',
    name: 'JobsPage',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/JobsPage.vue')
  },

  {
    path: '/book-a-trial',
    name: 'BookATrial',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/BookATrialPage.vue')
  }
  
]



var props = {duration : 0.75, ease: 'power4.inOut'}
var props2 = {duration : 0.5, ease: 'power4.inOut'}

var transitionIn = null;
var transitionOut = null;

const router = new VueRouter({
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {

  console.log("window.isPageTransitionRunning", window.isPageTransitionRunning);


  if (window.isPageTransitionRunning) {
    
    next()

  } else {
    
    if (from.name != null) {
      
      // $("body").addClass("block-scroll");

      router.app.$eventHub.$emit('on-before-route-change');

      window.isPageTransitionRunning = true

      transitionIn && transitionIn.kill()
      transitionIn = gsap.timeline({
        onStart: function () {
          $('.page-switch-container').removeClass('invisible')
        },
        onComplete: () => {
          transitionIn = null
          next()
        }
      })
      .set(['.page-switch-animation', '.page-switch-background'], { y: 0 })
      .to('.page-switch-animation', { y: '-100%', ...props }, 'sync')
      .to('.page-switch-background', { y: '45%', ...props }, 'sync')
    } else {
      next ()
    }
  }
})

router.afterEach((to, from) => {
  
  if (from.name != null) {
    transitionIn && transitionIn.kill()
    transitionOut && transitionOut.kill()
    transitionOut = gsap.timeline({
      delay: 0.1,
      onComplete: () => {
        transitionOut = null;
        window.isPageTransitionRunning = false;
        $('.page-switch-container').addClass('invisible');
        // $("body").removeClass("block-scroll");

        $(document).scrollTop(0);
      }
    })
    .to('.page-switch-animation', { y: '-200%', ...props2 }, 'sync2')
    .to('.page-switch-background', { y: '90%', ...props2 }, 'sync2')
  }
  
})

export default router
