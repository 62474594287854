<template v-on:refreshInstagram="refreshInstagrams">
    <canvas ref="canvas" class="wallpaper-canvas"></canvas>
</template>

<script>
import WallpaperFixed from '@/libs/wallpaperFixed.js'

export default {
    props: {
        noscroll: {
            type: Boolean,
            default: true
        },
        refresh: {
            type: Boolean,
            // default: true
        }
    },
    data(){
        return{
            refreshinstagram: false,
        }
    },
    mounted(){
        this.wallpaperCanvas = new WallpaperFixed (this.$refs.canvas, this.noscroll, this.refresh);
    },
    methods: {
        refreshInstagrams   : function (visibility){
            this.wallpaperCanvas.setActive(visibility);
        }
    },
    beforeDestroy() {
        this.wallpaperCanvas.destroy();
    },
    watch: {
        refreshinstagram: function (val) {
            console.log("CHANGED: ", val);
        },
    }
}
</script>