import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    windowWidth: 0,
    windowHeight: 0,
  },
  mutations: {
    onResize (state, payload) {
      state.windowWidth   = payload.windowWidth;
      state.windowHeight  = payload.windowHeight;
    }
  },
  actions: {
    onResize (context, payload) {
      context.commit('onResize', payload);
    }
  },
  modules: {
  },
  getters: {
    windowWidth:  state => state.windowWidth,
    windowHeight: state => state.windowHeight,
  }
})
