<template>
  <div id="app" ref="landing">

    <div class="fixed w-screen h-screen top-0 left-0 z-0">
      <WallpaperFixed :noscroll="false" ref="wallpaperFix"></WallpaperFixed>
    </div>

    <router-view class="z-10 relative"/>
    
    <FollowInstagram @componentVisibility="instagramVisibility"></FollowInstagram>
    <Footer></Footer>

    <div class="resize-dark-apla z-50"></div>

    <div class="page-switch-container invisible">
      <div class="page-switch-animation relative">
        <div class="page-switch-background">
          <WallpaperFixed :noscroll="false" :refresh="false"></WallpaperFixed>
        </div>
      </div>
    </div>
    
    <NavBar></NavBar>

  </div>
</template>

<script>
/* eslint-disable */
import NavBar from '@/components/NavBar.vue'
import FollowInstagram from '@/components/FollowInstagram.vue'
import Footer from '@/components/Footer.vue'
import WallpaperFixed from '@/components/WallpaperFixed.vue'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import SplitText from '@/libs/gsap/SplitText.js';
import PixiPlugin from 'gsap/PixiPlugin';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import * as PIXI from 'pixi.js';
import '@pixi/polyfill';

window.PIXI = PIXI;
// require("pixi-layers")

PixiPlugin.registerPIXI(PIXI);
PIXI.utils.skipHello();

// PIXI.Application.registerPlugin("pixi-layers");

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(PixiPlugin);
        
export default {
  components: { NavBar, FollowInstagram, Footer, WallpaperFixed },
  mounted (){
    
    window.appRef = this.$refs.landing;

    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  data (){
    return {
      refreshinstagram: false,
    }
  },
  methods: {
    onResize(){
      document.documentElement.style.setProperty('--vw', `${window.innerWidth}px`);
      document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
      this.$store.dispatch('onResize', { windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    },
    instagramVisibility(visibility){
      this.refreshinstagram = visibility;

      // WallpaperFixed.refreshinstagram = visibility;

      this.$refs.wallpaperFix.refreshInstagrams(this.refreshinstagram);
    }
  }
}
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
